import { memo, useCallback } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { TwitterShareButton, FacebookShareButton } from 'react-share';
import * as Device from 'react-device-detect';
import { useRouter } from 'next/router';

import Image from '@/modules/components/image';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',

      '& .iconMain': {
        position: 'relative',
        display: 'flex',
        backgroundColor: 'rgba(255,255,255,.8)',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 8px 12px #000',
        backdropFilter: 'saturate(2) blur(4px)',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        cursor: 'pointer',
        outline: 0,
        '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',

        '& .iconMainInner': {
          display: 'block',
          width: '24px',
          height: '24px',
          background:
            'url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0D%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M4.25%209C5.1515%209%205.95175%209.40575%206.50075%2010.0357L14.8182%205.87775C14.7747%205.6745%2014.75%205.46525%2014.75%205.25C14.75%203.59325%2016.0932%202.25%2017.75%202.25C19.4068%202.25%2020.75%203.59325%2020.75%205.25C20.75%206.90675%2019.4068%208.25%2017.75%208.25C16.8485%208.25%2016.049%207.84425%2015.4993%207.21425L7.18175%2011.3723C7.22525%2011.5755%207.25%2011.7847%207.25%2012C7.25%2012.2153%207.22525%2012.4245%207.18175%2012.6277L15.4993%2016.7858C16.0483%2016.1565%2016.8485%2015.75%2017.75%2015.75C19.4068%2015.75%2020.75%2017.0932%2020.75%2018.75C20.75%2020.4068%2019.4068%2021.75%2017.75%2021.75C16.0932%2021.75%2014.75%2020.4068%2014.75%2018.75C14.75%2018.5347%2014.7747%2018.3255%2014.8182%2018.1223L6.50075%2013.9643C5.951%2014.5935%205.1515%2015%204.25%2015C2.59325%2015%201.25%2013.6568%201.25%2012C1.25%2010.3432%202.59325%209%204.25%209%22%20fill%3D%22black%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A) no-repeat center center',
        },
      },

      '& .snsIcons': {
        position: 'absolute',
        bottom: 0,
        flexDirection: 'column',
        transition: 'all .4s ease',
        maxHeight: '0',
        overflow: 'hidden',

        '& .snsIcon': {
          bottom: 0,
          boxSizing: 'border-box',
          width: '40px',
          height: '40px',
          opacity: 0,
          transition: 'all .4s ease',
          transform: 'scale(0,0)',
        },
      },

      '&:hover': {
        '& .iconMain': {
          backgroundColor: 'rgba(255,255,255,1)',
          backdropFilter: 'saturate(2) blur(4px)',
        },

        '& .snsIcons': {
          bottom: '40px',
          display: 'flex',

          '& .snsIcon': {
            marginBottom: '12px',
            opacity: 1,
            transform: 'scale(1,1)',
          },
        },
        '&.shareIcons': {
          '& .snsIcons': {
            maxHeight: '200px',
          },
        },
      },
    },
  }),
);

const { NEXT_PUBLIC_RTV_SPORTS_URL } = process.env;
const { NEXT_PUBLIC_IM_URL } = process.env;

type Props = {
  className?: string;
  url?: string;
  title?: string;
};
const ShareSns: React.FC<Props> = memo(
  ({
    className = '',
    url = `${NEXT_PUBLIC_RTV_SPORTS_URL}/pacificleague/`,
    title = 'Rakuten パ・リーグSpecial 動画：楽天TV',
  }) => {
    const classes = useStyles();
    const router = useRouter();

    const showSpShare = useCallback(async () => {
      if (navigator && navigator.share) {
        await navigator
          .share({
            title: '楽天TV',
            text: document.title,
            url: router.asPath,
          })
          .catch((_error) => null);
      }
    }, [router]);

    return (
      <div className={`${className} ${classes.root} ${Device.isBrowser && !Device.isTablet ? 'shareIcons' : ''} `}>
        <span className="iconMain" onTouchEnd={showSpShare} role="button" tabIndex={0}>
          <span className="iconMainInner" />
        </span>
        <ul className="snsIcons">
          <li className="snsIcon">
            <TwitterShareButton url={url} title={title}>
              <Image
                src={`${NEXT_PUBLIC_IM_URL}/img/pc/sns_widget/sns-x-circle_light.svg`}
                alt=""
                width={40}
                height={40}
              />
            </TwitterShareButton>
          </li>
          <li className="snsIcon">
            <FacebookShareButton url={url}>
              <Image
                src={`${NEXT_PUBLIC_IM_URL}/img/pc/sns_widget/sns-facebook-circle.svg`}
                alt=""
                width={40}
                height={40}
              />
            </FacebookShareButton>
          </li>
        </ul>
      </div>
    );
  },
);

export default ShareSns;
