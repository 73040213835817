/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useRef } from 'react';
import Script from 'next/script';
import * as Device from 'react-device-detect';

import CookieUtils from '@/modules/cookies';
import { Logger } from '@/utils';

declare global {
  interface Window {
    _rat: JSONValue;
  }
}
type Props = {
  ratParams?: Record<string, string>;
};
const RAT: React.FC<Props> = ({ ratParams = {} }) => {
  const ratObjectsRef = useRef<Record<string, string>>();
  useEffect(() => {
    if (!window._rat) {
      window._rat = {};
    }
    const param = CookieUtils.get({ key: 'rtv_rat' });
    if (param) {
      try {
        window._rat = JSON.parse(param);
        CookieUtils.set({ key: 'rtv_rat', value: '', maxAge: 0 });
      } catch (error) {
        Logger.warn('json parse error', error);
      }
    }
  }, []);

  useEffect(() => {
    ratObjectsRef.current = { ...ratParams, ...(window._rat as Record<string, string>) };
  }, [ratParams]);

  return (
    <>
      <input type="hidden" name="rat" id="ratAccountId" value="438" />
      <input type="hidden" name="rat" id="ratServiceId" value="1" />
      <input type="hidden" name="rat" id="ratPageLayout" value={`${Device.isMobile ? 'sp' : 'pc'}`} />
      {ratObjectsRef.current &&
        Object.keys(ratObjectsRef.current).map((key) => (
          <input type="hidden" name="rat" id={key} key={key} value={ratObjectsRef.current[key]} />
        ))}
      <Script strategy="lazyOnload" src="//r.r10s.jp/com/rat/js/rat-main.js" />
    </>
  );
};

export default RAT;
