import { createContext, useContext, useEffect, useReducer } from 'react';
import dayjs from 'dayjs';

import { useAuthStateContext } from '@/modules/auth/providers';
import webApi from '@/modules/api/web-api';
import * as WebApiTypes from '@/modules/api/@types';
import { CONTENT, PAGE_TYPE_TOP } from '@/modules/constants/pacificleague';
import { getDeviceId } from '@/utils/device';

const prefix = 'pacificleagueTopStore/';
export const TopStoreActionTypes = {
  DELETE_BOOKMARK: `${prefix}DELETE_BOOKMARK`,
  FETCH_INITIALIZE: `${prefix}FETCH_INITIALIZE`,
  SET_BOOKMARK: `${prefix}SET_BOOKMARK`,
} as const;
export type TopStoreActionTypes = (typeof TopStoreActionTypes)[keyof typeof TopStoreActionTypes];

type State = {
  units: WebApiTypes.Unit[];
  deliveryContentIdList: string[];
  liveDateList: { [key: string]: string[] };
  currentLiveDate: string;
  isGameToday: boolean;
  isBookmarked: boolean;
};

const initialState: State = {
  units: [],
  deliveryContentIdList: [],
  liveDateList: {},
  currentLiveDate: null,
  isGameToday: false,
  isBookmarked: false,
};

type Action =
  | {
      type: typeof TopStoreActionTypes.DELETE_BOOKMARK;
    }
  | {
      type: typeof TopStoreActionTypes.FETCH_INITIALIZE;
      payload: {
        units: WebApiTypes.Unit[];
        childList: WebApiTypes.ContentChild[];
        isBookmarked?: boolean;
      };
    }
  | {
      type: typeof TopStoreActionTypes.SET_BOOKMARK;
    };

const TopStoreStateContext = createContext<State>(initialState);
const TopStoreDispatchContext = createContext<React.Dispatch<Action>>(() => null);
export const useTopStoreStateContext = (): State => useContext<State>(TopStoreStateContext);
export const useTopStoreDispatchContext = (): React.Dispatch<Action> =>
  useContext<React.Dispatch<Action>>(TopStoreDispatchContext);

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case TopStoreActionTypes.DELETE_BOOKMARK: {
      return {
        ...state,
        isBookmarked: false,
      };
    }
    case TopStoreActionTypes.FETCH_INITIALIZE: {
      const { units, childList, isBookmarked } = action.payload;

      const deliveryContentIdList: string[] = [];
      const liveDateList: { [key: string]: string[] } = {};
      childList?.forEach((v) => {
        Object.keys(v?.item_list)?.forEach((index) => {
          const item = v.item_list[index];
          if (item?.is_delivery_at?.toString() === '1') {
            deliveryContentIdList.push(v.id);
          }
          if (item?.delivery_started_at) {
            const date = item.delivery_started_at.replace(/ .+$/, '');
            if (!liveDateList[date]) {
              liveDateList[date] = [];
            }
            liveDateList[date].push(v.id);
          }
        });
      });

      // 試合当日チェック
      let isGameToday = false;
      // ライブ表示日付(1:00を基点とした日付)
      let liveViewDate: dayjs.Dayjs = null;
      const now = dayjs();
      if (now.hour() >= 1) {
        liveViewDate = now.startOf('day');
      } else {
        liveViewDate = now.add(-1, 'd').startOf('day');
      }

      let currentLiveDate = '';
      for (const liveDate in liveDateList) {
        if (dayjs(liveDate).isBefore(liveViewDate)) {
          // eslint-disable-next-line no-continue
          continue;
        }
        currentLiveDate = dayjs(liveDate).format('YYYY-MM-DD');
        if (dayjs(liveDate).format('YYYY-MM-DD') === liveViewDate.format('YYYY-MM-DD')) {
          isGameToday = true;
        }
        break;
      }

      return {
        ...state,
        units,
        deliveryContentIdList,
        liveDateList,
        currentLiveDate,
        isGameToday,
        isBookmarked,
      };
    }
    case TopStoreActionTypes.SET_BOOKMARK: {
      return {
        ...state,
        isBookmarked: true,
      };
    }
    default: {
      throw new Error('no such action type');
    }
  }
};

const fetchUnitList = async (): Promise<WebApiTypes.Unit[]> => {
  const { body } = await webApi()
    .unit.list.get({
      query: {
        device_id: getDeviceId(),
        isp_id: 1,
        rating_type: 18,
        page_type: PAGE_TYPE_TOP,
        browser_app_type: 1,
      },
    })
    .catch(async () => ({
      body: {
        result: {
          unit_list: [] as WebApiTypes.Unit[],
        },
        status: 'fail',
      },
    }));

  return body?.result?.unit_list ?? ([] as WebApiTypes.Unit[]);
};

const fetchContentChildList = async (): Promise<WebApiTypes.ContentChildList> => {
  const { body } = await webApi()
    .content.child_list.get({
      query: {
        content_id: CONTENT.ID,
        device_id: getDeviceId(),
        isp_id: 1,
        rating_type: 18,
        offset: 0,
        count: 6,
      },
      config: {
        withCredentials: true,
      },
    })
    .catch(async () => ({
      body: {
        result: null as WebApiTypes.ContentChildList,
        status: 'fail',
      },
    }));

  return body?.result;
};

const postViewHistory = async (): Promise<void> => {
  await webApi()
    .web.view_history.post({
      body: {
        content_id: CONTENT.ID.toString(),
        adult_flg: 0,
        content_name: CONTENT.NAME_TITLE,
        type: CONTENT.TYPE,
        rating_type: CONTENT.RATING_TYPE,
        resale_type: CONTENT.RESALE_TYPE,
        included_pacificleague: 1,
      },
      config: {
        withCredentials: true,
      },
    })
    .catch(async () => ({
      body: {
        result: 'error',
        status: 'fail',
      },
    }));
};

const fetchIsBookmark = async (): Promise<WebApiTypes.IsBookmark> => {
  const { body } = await webApi()
    .bookmark.isbookmark.get({
      query: {
        content_id: CONTENT.ID,
      },
      config: {
        withCredentials: true,
      },
    })
    .catch(async () => ({
      body: {
        result: null as WebApiTypes.IsBookmark,
        status: 'fail',
      },
    }));

  return body?.result;
};

type Props = {
  children?: React.ReactNode;
};
const TopStoreProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isAuthenticated, isFetched } = useAuthStateContext();

  useEffect(() => {
    const fetchData = async () => {
      if (!isFetched) return;

      const units = await fetchUnitList();

      // these line was done comment out to reduce server load
      // let childList = null as WebApiTypes.ContentChildList;
      // if ([1, 2].includes(Number(plContent.bg_template_flag))) {
      //   if ([2, 5].includes(Number(plContent.type))) {
      const childList = await fetchContentChildList();
      // }
      // }

      if (!isAuthenticated) {
        dispatch({
          type: TopStoreActionTypes.FETCH_INITIALIZE,
          payload: {
            units,
            childList: childList?.content_list,
          },
        });

        return;
      }

      postViewHistory().finally(() => null);

      const bookmark = await fetchIsBookmark();

      dispatch({
        type: TopStoreActionTypes.FETCH_INITIALIZE,
        payload: {
          units,
          childList: childList?.content_list,
          isBookmarked: !!bookmark?.is_bookmark,
        },
      });
    };

    fetchData().finally(() => null);
  }, [isAuthenticated, isFetched]);

  return (
    <TopStoreStateContext.Provider value={state}>
      <TopStoreDispatchContext.Provider value={dispatch}>{children}</TopStoreDispatchContext.Provider>
    </TopStoreStateContext.Provider>
  );
};

export default TopStoreProvider;
