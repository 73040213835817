/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable dot-notation */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useRef, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import jQuery from 'jquery';

import CookieUtils from '@/modules/cookies';
import { Logger } from '@/utils';

const { NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID } = process.env;
const tagManagerArgs = {
  gtmId: NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
};

declare global {
  interface Window {
    jQuery: JQueryStatic;
    $: JQueryStatic;
    trackingParam: JSONValue;
    _uah: JSONValue;
    karteProps: JSONValue;
  }
}

type Props = {
  dataLayer?: JSONValue;
  trackingParam?: JSONValue;
  uah?: JSONValue;
  karte?: JSONValue;
  playVideoData?: JSONValue;
};
const useGoogleTagManager = (props: Partial<Props> = {}) => {
  const isSendForDatalayer = useRef<boolean>(false);
  const isSendForTrackingParam = useRef<boolean>(false);
  const isSendForUah = useRef<boolean>(false);
  const isSendForKarte = useRef<boolean>(false);
  const isSendPlayVideoData = useRef<boolean>(false);

  useEffect(() => {
    // Inject jQuery to window for google tag manager
    window.jQuery = jQuery;
    window.$ = jQuery;
  }, []);

  useEffect(() => {
    // google tag manager
    if (isSendForDatalayer.current) return;
    isSendForDatalayer.current = true;

    const data = CookieUtils.get({ key: 'rtv_dataLayer' });
    if (data) {
      try {
        CookieUtils.set({ key: 'rtv_dataLayer', value: '', maxAge: 0 });
      } catch (error) {
        Logger.warn('json parse error', error);
      }

      const rtvDataLayer: any = JSON.parse(data);

      if (props?.dataLayer) {
        const propsDataLayer: any = props?.dataLayer;
        if (rtvDataLayer.ecommerce?.purchase?.products?.[0]) {
          rtvDataLayer.ecommerce.purchase.products[0].name = propsDataLayer.ecommerce?.detail?.products?.[0]?.name;
        }
      }

      TagManager.initialize({ ...tagManagerArgs, dataLayer: rtvDataLayer as Record<string, unknown> });

      return;
    }

    if (props?.dataLayer) {
      TagManager.initialize({ ...tagManagerArgs, dataLayer: props?.dataLayer as Record<string, unknown> });
    }
  }, [props?.dataLayer]);

  useEffect(() => {
    // Karte
    if (isSendForTrackingParam.current) return;
    isSendForTrackingParam.current = true;

    if (!window.trackingParam) {
      window.trackingParam = {};
    }
    const param = CookieUtils.get({ key: 'rtv_trackingParam' });
    if (param) {
      try {
        window.trackingParam = JSON.parse(param);
        CookieUtils.set({ key: 'rtv_trackingParam', value: '', maxAge: 0 });
      } catch (error) {
        Logger.warn('json parse error', error);
      }

      return;
    }

    if (props?.trackingParam) {
      window.trackingParam = props.trackingParam;
    }
  }, [props?.trackingParam]);

  useEffect(() => {
    if (isSendForUah.current) return;
    isSendForUah.current = true;

    if (!window._uah) {
      window._uah = {};
    }
    const param = CookieUtils.get({ key: 'rtv_uah' });
    if (param) {
      try {
        window._uah = JSON.parse(param);
        CookieUtils.set({ key: 'rtv_uah', value: '', maxAge: 0 });
      } catch (error) {
        Logger.warn('json parse error', error);
      }

      return;
    }

    if (props?.uah) {
      window._uah = props.uah;
    }
  }, [props?.uah]);

  useEffect(() => {
    if (isSendForKarte.current) return;
    isSendForKarte.current = true;

    if (!window.karteProps) {
      window.karteProps = {};
    }

    if (props?.karte) {
      window.karteProps = props.karte;
    }
  }, [props?.karte]);

  useEffect(() => {
    if (!props?.playVideoData || isSendPlayVideoData.current) return;

    isSendPlayVideoData.current = true;

    TagManager.initialize({ ...tagManagerArgs, dataLayer: props?.playVideoData as Record<string, unknown> });
  }, [props?.playVideoData]);
};

export default useGoogleTagManager;
