/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-array-index-key */
import { memo } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Swiper as SwiperBase, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation]);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .swiper-container': {},
      '& .swiper-button-disabled': {
        display: 'none',
      },
      '& .swiper-button-prev:after,& .swiper-button-next:after': {
        display: 'none',
      },
      '& .swiper-button-prev:not(.swiper-button-disabled),& .swiper-button-next:not(.swiper-button-disabled)': {
        display: 'none',
        '@media(min-width: 970px)': {
          top: '74px',
          display: 'block',
          width: '36px',
          height: '36px',
          borderRadius: '50%',
          boxShadow: '0 0 8px 2px #000',
          cursor: 'pointer',
          background:
            'url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2236%22%20height%3D%2236%22%20viewBox%3D%220%200%2036%2036%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15%2012.4689L20.2207%2017.7557L15%2023.0426L16.6073%2024.6666L23.4467%2017.7557L16.6073%2010.8448L15%2012.4689Z%22%20fill%3D%22black%22%2F%3E%3C%2Fsvg%3E) no-repeat center center #fff',
        },
      },
      '& .swiper-button-prev': {
        left: '0',
        right: 'auto',
        transform: 'rotate(180deg)',
      },
      '& .swiper-button-next': {
        left: 'auto',
        right: 0,
      },
    },
  }),
);

type Props = {
  className?: string;
  children: React.ReactNode;
} & SwiperBase;
const Swiper: React.FC<Props> = memo((props: Props) => {
  const classes = useStyles();

  const propsSwiper: SwiperBase = props;

  return (
    <SwiperBase {...propsSwiper} className={`${props.className} ${classes.root}`}>
      {(props.children as React.ReactNode[]).map((child, index) => (
        <SwiperSlide key={index.toString()}>{child}</SwiperSlide>
      ))}
    </SwiperBase>
  );
});

export default Swiper;
