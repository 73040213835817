/* eslint-disable no-irregular-whitespace */
import { useMemo, memo } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import * as Device from 'react-device-detect';
import dayjs from 'dayjs';

import {
  MULTI_ID_LIVE,
  MULTI_IDS_TEAM,
  ISSUE_UNIT_TITLE,
  TEAMS,
  GAME_STATE_TYPE,
} from '@/modules/constants/pacificleague';
import * as WebApiTypes from '@/modules/api/@types';
import Image from '@/modules/components/image';
import { usePlStoreStateContext } from '@/modules/domains/pacificleague/providers';
import { useTopStoreStateContext } from '@/modules/domains/pacificleague/top-container/providers';
import { useIsAuthenticated } from '@/modules/auth/providers';

const { NEXT_PUBLIC_RTV_WEB_URL } = process.env;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'block',
      color: '#ffffff',
      padding: '8px',
      '&:hover': {
        '& .img': {
          transform: 'scale(1.07)',
        },
        '& .ttl': {
          color: '#ffffff',
        },
      },
      '&.isMobile': {
        '&:hover': {
          '& .img': {
            transform: 'none',
          },
          '& .ttl': {
            color: '#aaa',
          },
        },
      },
      '&.live': {
        '& .img': {
          '&:after': {
            position: 'absolute',
            top: '6px',
            right: '6px',
            display: 'inline-block',
            padding: '4.5px 4px 3.5px 5px',
            borderRadius: '2px',
            textShadow: '0 1px 3px rgb(0 0 0 / 40%)',
            fontSize: '12px',
            fontFeatureSettings: 'palt',
            backgroundColor: '#ef2222',
            color: '#fff',
            content: '"LIVE中"',
          },
        },
      },

      '& .img': {
        position: 'relative',
        display: 'block',
        width: '224px',
        height: '126px',
        verticalAlign: 'bottom',
        overflow: 'hidden',
        marginBottom: '8px',
        borderRadius: '4px',
        transition: 'transform .2s ease,-webkit-transform .2s ease',

        '& .markArea': {
          position: 'absolute',
          top: '4px',
          left: 0,
          paddingTop: '6px',

          '& .mark': {
            marginLeft: '6px',
            padding: '4.5px 4px 3.5px 5px',
            borderRadius: '2px',
            textShadow: '0 1px 3px rgb(0 0 0 / 40%)',
            fontSize: '12px',
            fontFeatureSettings: "'palt'",

            '&.markFree': {
              backgroundColor: '#00b900',
              color: '#ffffff',
            },

            '&.markLabel': {
              backgroundColor: 'rgba(255,255,255,.6)',
              color: '#000000',
              textShadow: 'none',
              letterSpacing: 'normal',
              backdropFilter: 'saturate(2) blur(8px)',
            },
          },
        },

        '& .play': {
          position: 'absolute',
          bottom: '8px',
          left: '8px',
          display: 'block',
          width: '44px',
          height: '44px',
          background:
            'url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20viewBox%3D%220%200%2040%2040%22%20fill%3D%22none%22%3E%3Ccircle%20cx%3D%2220%22%20cy%3D%2220%22%20r%3D%2220%22%20fill%3D%22black%22%20fill-opacity%3D%220.6%22%2F%3E%3Ccircle%20cx%3D%2220%22%20cy%3D%2220%22%20r%3D%2219.5%22%20stroke%3D%22white%22%20stroke-width%3D%221%22%2F%3E%3Cpath%20d%3D%22M15.5%2029V11L27.5%2020L15.5%2029Z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E) no-repeat center center',
        },
      },

      '& .ttl': {
        display: '-webkit-box',
        height: '2.6em',
        overflow: 'hidden',
        color: '#aaaaaa',
        whiteSpace: 'normal',
        fontSize: '14px',
        lineHeight: 1.3,
        boxOrient: 'vertical',
        lineClamp: 2,

        '&.issue': {
          height: '5.2em',
          lineClamp: 4,
        },
      },

      '& .game-startTime': {
        fontSize: '30px',
        color: '#fff',
        fontFamily: 'arial',
        fontWeight: 700,
        textAlign: 'center',
      },

      '& .game-stadiumName': {
        position: 'absolute',
        bottom: '8px',
        display: 'block',
        width: '100%',
        textAlign: 'center',
        textShadow: '0 1px 4px rgb(0 0 0 / 30%)',
        fontSize: '12px',
      },

      '& .game-stateName': {
        color: '#fff',
        fontSize: '20px',
        textAlign: 'center',
        transition: 'all 0.3s linear',

        '&-dispScore': {
          color: '#aaa',
          fontSize: '14px',
        },
      },
      '& .game-totalScore': {
        overflow: 'hidden',
        transition: 'all 0.3s linear',
        maxHeight: 0,
        color: '#fff',
        textAlign: 'center',
        fontWeight: 700,
        fontSize: '36px',
        fontFamily: 'arial',
        fontFeatureSettings: "'palt'",

        '&-dispScore': {
          maxHeight: '50px',
        },
      },
    },
  }),
);

type Props = {
  unit: WebApiTypes.Unit;
  element: WebApiTypes.Element;
  isShowLabel?: boolean;
  isShowLiveLabel?: boolean;
};
const Element: React.FC<Props> = memo(({ unit, element, isShowLabel = false, isShowLiveLabel = false }) => {
  const classes = useStyles();
  const isAuthenticated = useIsAuthenticated();
  const { purchaseInfo, isDispScore } = usePlStoreStateContext();
  const { isGameToday } = useTopStoreStateContext();

  // ライブユニットかどうか
  const isLive = useMemo(
    () => Number(unit?.type) === 16 && Number(unit?.multi_id) === MULTI_ID_LIVE && unit?.element_list?.length > 0,
    [unit],
  );

  // チームページかどうか
  const isTeam = useMemo(() => MULTI_IDS_TEAM.includes(Number(unit?.multi_id)), [unit]);

  // 特集記事かどうか
  const isIssue = useMemo(() => isTeam && unit?.title === ISSUE_UNIT_TITLE, [isTeam, unit?.title]);

  // 自動再生かどうか(再生アイコン)
  const isEnablePlay = useMemo(() => {
    if (isIssue) return false;

    const hasPlayRight =
      element.is_free || (element.is_member_free && isAuthenticated) || (purchaseInfo && element.is_pay);
    if (isLive) return isShowLiveLabel && hasPlayRight;

    return hasPlayRight;
  }, [
    element.is_free,
    element.is_member_free,
    element.is_pay,
    isAuthenticated,
    isIssue,
    isLive,
    isShowLiveLabel,
    purchaseInfo,
  ]);

  const fallbackImageUrl = useMemo(
    () => (isIssue ? TEAMS[unit?.multi_id].stadiumImgUrl : null),
    [isIssue, unit?.multi_id],
  );

  const ttlDatetime = useMemo(() => {
    const ret = element.name.match(/(\d{4}\/\d{1,2}\/\d{1,2})(\s\d{1,2}:\d{2})?[ 　]*(.*)/);
    const time = (ret?.[1] || '') + (ret?.[2] || '');

    return element.name === time ? '' : time;
  }, [element.name]);

  const ttlName = useMemo(
    () => element.name.replace(/(\d{4}\/\d{1,2}\/\d{1,2})(\s\d{1,2}:\d{2})?[ 　]*(.*)/, '$3'),
    [element.name],
  );

  const labelName = useMemo(
    () => ((isTeam || isShowLabel) && element.label_class && element.label_name ? element.label_name : null),
    [isTeam, isShowLabel, element.label_class, element.label_name],
  );

  const url = useMemo(() => {
    if (isIssue) {
      return `${NEXT_PUBLIC_RTV_WEB_URL}/static/pacificleague/issue/${TEAMS[unit?.multi_id]?.pageUrlParam}/${
        element.id
      }/#unkr_trgt`;
    }

    return `/pacificleague/content/${element.id}/`;
  }, [isIssue, element.id, unit?.multi_id]);

  return (
    <a className={`${classes.root} ${isShowLiveLabel ? 'live' : ''} ${Device.isMobile ? 'isMobile' : ''}`} href={url}>
      <span className="img">
        {/* TODO fallbackImageType はパ・リーグ専用の作るべき */}
        <Image
          src={element.image_url_thumb}
          fallbackImageType={isIssue ? null : 'main'}
          fallbackImageUrl={fallbackImageUrl}
          width={224}
          height={126}
          alt={element.name}
        />
        <span className="game-stadiumName">
          {element.pacific_league?.stadium_name && `@${element.pacific_league.stadium_name}`}
        </span>
        <div className="markArea">
          {(!isAuthenticated || (isAuthenticated && !purchaseInfo)) && (element.is_free || element.is_member_free) ? (
            <span className="mark markFree">{element.is_free ? '無料' : element.is_member_free ? '会員無料' : ''}</span>
          ) : null}
          {labelName ? <span className="mark markLabel">{labelName}</span> : null}
        </div>
        {isEnablePlay ? <span className="play" /> : null}
      </span>
      {isGameToday && element.pacific_league ? (
        [
          GAME_STATE_TYPE.END,
          GAME_STATE_TYPE.PLAY,
          GAME_STATE_TYPE.SUSPEND,
          GAME_STATE_TYPE.CHECK,
          GAME_STATE_TYPE.GAME_SET,
          GAME_STATE_TYPE.STOP,
          GAME_STATE_TYPE.NO_GAME,
        ].includes(element.pacific_league?.game_state_id) ? (
          <>
            {/* 試合中 */}
            <p className={`game-stateName ${isLive && isDispScore && 'game-stateName-dispScore'}`}>
              {element.pacific_league?.live_inning_info || element.pacific_league?.game_state_name || ''}
            </p>
            <p className={`game-totalScore ${isLive && isDispScore && 'game-totalScore-dispScore'}`}>
              {element.pacific_league?.live_inning_info.replace(/[^0-9]/g, '') === '1'
                ? `${element.pacific_league?.home_total_score || '0'} - ${
                    element.pacific_league?.visitor_total_score || '0'
                  }`
                : `${element.pacific_league?.home_total_score || ''} - ${
                    element.pacific_league?.visitor_total_score || ''
                  }`}
            </p>
          </>
        ) : (
          // 試合前
          <p className="game-startTime">{ttlDatetime && dayjs(ttlDatetime).format('HH:mm')}</p>
        )
      ) : (
        // 試合当日以前
        <p className={`ttl ${isIssue ? 'issue' : ''}`}>
          {ttlDatetime && (
            <>
              {ttlDatetime}
              <br />
            </>
          )}
          {ttlName}
        </p>
      )}
    </a>
  );
});

export default Element;
