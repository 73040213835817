/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect } from 'react';
import Router from 'next/router';

const saveScrollPos = () => {
  sessionStorage.setItem(`scrollPos${window.location.href}`, `${window.scrollY}`);
};

const restoreScrollPos = () => {
  const sessionStorageKey = `scrollPos${window.location.href}`;
  const scrollValueItem = sessionStorage.getItem(sessionStorageKey);
  const navigation = window?.performance?.getEntriesByType('navigation')?.[0];
  // @ts-ignore
  const perfEntries = navigation.type;

  if (perfEntries !== 'back_forward') {
    return;
  }

  if (scrollValueItem) {
    window.scrollTo(0, Number(scrollValueItem));
    sessionStorage.removeItem(`scrollPos${window.location.href}`);
  }
};

const useRestoreScroll = () => {
  useEffect(() => {
    window.addEventListener('load', restoreScrollPos, false);
    window.addEventListener('beforeunload', saveScrollPos, false);
    Router.events.on('routeChangeStart', saveScrollPos);

    return () => {
      window.removeEventListener('load', restoreScrollPos, false);
      window.removeEventListener('beforeunload', saveScrollPos, false);
      Router.events.off('routeChangeStart', saveScrollPos);
    };
  }, []);
};

export default useRestoreScroll;
