/* eslint-disable react/no-array-index-key */
import { memo, useCallback, useMemo } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import {
  usePlStoreStateContext,
  usePlStoreDispatchContext,
  PlStoreActionTypes,
} from '@/modules/domains/pacificleague/providers';
import { useTopStoreStateContext } from '@/modules/domains/pacificleague/top-container/providers';
import UnitTitle from '@/modules/domains/pacificleague/top-container/unit-title';
import Element from '@/modules/domains/pacificleague/top-container/element';
import Swiper from '@/modules/domains/pacificleague/top-container/components/swiper';
import * as WebApiTypes from '@/modules/api/@types';
import { MULTI_ID_LIVE } from '@/modules/constants/pacificleague';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      paddingTop: '28px',
      '& .swiperWrap': {
        position: 'relative',
        margin: '12px 0 0 -8px',
      },
      '& .unit_elm': {
        '& .swiper-slide': {
          width: '240px',
        },
      },
      '& .swiper-container': {
        position: 'static',
        [theme.breakpoints.down('sm')]: {
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          'scrollbar-width': 'none',
          '-ms-overflow-style': 'none',
        },
      },
      '& > .score': {
        position: 'absolute',
        top: '-2px',
        right: '24px',
        height: '28px',
        display: 'inline-flex',
        color: '#aaa',
        fontSize: '13px',
        cursor: 'pointer',
        alignItems: 'center',
        '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
        outline: '0',

        '&:hover': {
          color: '#fff',
        },

        '& > .label': {
          marginTop: '3px',
          paddingRight: '6px',
          cursor: 'pointer',
          alignSelf: 'center',
        },
      },
    },
    switch: {
      position: 'relative',
      display: 'block',
      width: '34px',
      height: '20px',
      marginTop: '4px',

      '& .thumb': {
        transform: 'translateX(0)',
        backgroundColor: '#9c9c9c',
        position: 'absolute',
        left: 0,
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        transition: 'all .2s ease',
      },

      '& .bar': {
        position: 'absolute',
        top: '50%',
        left: 0,
        width: '100%',
        height: '14px',
        borderRadius: '7px',
        opacity: 0.5,
        transition: 'all .2s ease',
        transform: 'translateY(-50%)',
        backgroundColor: '#9c9c9c',
      },

      '&.on': {
        '& .thumb': {
          transform: 'translateX(14px)',
          backgroundColor: '#2c98f0',
        },
        '& .bar': {
          backgroundColor: '#2c98f0',
        },
      },
    },
  }),
);

type Props = {
  className?: string;
  unit: WebApiTypes.Unit;
  isShowLabel?: boolean;
};
const Unit: React.FC<Props> = memo(({ className = '', unit, isShowLabel = false }) => {
  const classes = useStyles();
  const { isDispScore } = usePlStoreStateContext();
  const { deliveryContentIdList, liveDateList, currentLiveDate, isGameToday } = useTopStoreStateContext();
  const dispatch = usePlStoreDispatchContext();

  // ライブユニットかどうか
  const isLive = useMemo(
    () => Number(unit?.type) === 16 && Number(unit?.multi_id) === MULTI_ID_LIVE && unit?.element_list?.length > 0,
    [unit],
  );

  // 表示エレメント
  const elements = useMemo(() => {
    if (isLive) {
      if (!currentLiveDate) {
        return [];
      }

      return unit.element_list.filter((element) => liveDateList[currentLiveDate].includes(element.id));
    }

    return unit.element_list;
  }, [currentLiveDate, isLive, liveDateList, unit.element_list]);

  const onClickDispScore = useCallback(() => {
    dispatch({ type: PlStoreActionTypes.CHANE_DISP_SCORE, payload: { isDispScore: !isDispScore } });
  }, [dispatch, isDispScore]);

  if (elements?.length === 0) {
    return null;
  }

  return (
    <section className={`lSec ${className} ${classes.root}`}>
      {isLive && isGameToday && (
        <span className="score" onClick={onClickDispScore} onKeyDown={() => null} role="button" tabIndex={0}>
          <span className="label">スコア表示</span>
          <span className={`${classes.switch} ${isDispScore ? 'on' : ''}`}>
            <span className="thumb" />
            <span className="bar" />
          </span>
        </span>
      )}
      <UnitTitle unit={unit} />
      <div className="swiperWrap">
        <Swiper
          className="unit_elm"
          navigation={true}
          slidesPerView="auto"
          freeMode={true}
          breakpoints={{
            '1024': {
              slidesPerGroup: 3,
            },
            '1280': {
              slidesPerGroup: 4,
            },
            '1440': {
              slidesPerGroup: 5,
            },
          }}
        >
          {elements.slice(0, 17).map((element, i) => (
            <Element
              key={i.toString()}
              unit={unit}
              element={element}
              isShowLabel={isShowLabel}
              isShowLiveLabel={isLive && deliveryContentIdList.includes(element.id)}
            />
          ))}
        </Swiper>
      </div>
    </section>
  );
});

export default Unit;
