import { useMemo, memo } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import { useTopStoreStateContext } from '@/modules/domains/pacificleague/top-container/providers';
import * as WebApiTypes from '@/modules/api/@types';
import { MULTI_ID_LIVE, MULTI_IDS_TEAM, ISSUE_UNIT_TITLE } from '@/modules/constants/pacificleague';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontFeatureSettings: "'palt'",
      letterSpacing: '-0.05em',
      display: 'inline-block',
      width: '100%',
      overflow: 'hidden',
      '@media (max-width: 969px)': {
        position: 'relative',
        whiteSpace: 'nowrap',
      },
      '& .title01_txt': {
        display: 'inline-block',
        verticalAlign: 'middle',
        fontSize: '24px',
        fontWeight: 700,
        color: '#fff',
        '@media (max-width: 769px)': {
          lineHeight: 1.2,
          maxWidth: 'calc(100% - 24px)',
          whiteSpace: 'normal',
        },
        '&Sub': {
          display: 'inline-block',
          verticalAlign: 'middle',
          fontSize: '14px',
          paddingLeft: '1em',
          fontWeight: 400,
        },
      },
      '& .title01_txt+.title01_shoulder': {
        paddingLeft: '1em',
      },
      '& .title01_shoulder': {
        display: 'inline-block',
        verticalAlign: 'middle',
        paddingLeft: '.4em',
      },
      '@media (max-width: 769px)': {
        '& .title01_shoulder': {
          display: 'none',
        },
        '& .multiId_1651 .title01_shoulder': {
          display: 'inline-block',
        },
      },
      '& .title01_more': {
        position: 'relative',
        display: 'inline-block',
        maxWidth: '24px',
        overflow: 'hidden',
        boxSizing: 'border-box',
        marginLeft: '8px',
        paddingRight: '24px',
        color: 'rgba(256,256,256, 0)',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        fontSize: '14px',
        transition: 'all .5s ease',
        '&Ico': {
          position: 'absolute',
          top: '50%',
          right: 0,
          display: 'inline-block',
          width: '24px',
          height: '24px',
          verticalAlign: 'middle',
          transform: 'translateY(-50%)',
          background:
            'url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22white%22%20d%3D%22M9.235%205L7.59%206.645L12.9333%2012L7.59%2017.355L9.235%2019L16.235%2012L9.235%205Z%22%20%2F%3E%3C%2Fsvg%3E) no-repeat center center',
        },
      },
      '&:hover': {
        '& .title01_more': {
          maxWidth: 200,
          color: 'rgba(256,256,256, 1)',
          '@media (max-width: 769px)': {
            maxWidth: '24px',
            color: 'rgba(256,256,256, 0)',
          },
        },
        opacity: 0.8,
      },
      '&.title01-live': {
        '&::before': {
          display: 'inline-block',
          verticalAlign: 'middle',
          marginRight: '.4em',
          content: "''",
          width: 24,
          height: 24,
          background:
            "url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%2210%22%20fill%3D%22url%28%23paint0_radial%29%22%20fill-opacity%3D%220.5%22%2F%3E%3Ccircle%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%224%22%20fill%3D%22%23EC0606%22%2F%3E%3Cdefs%3E%3CradialGradient%20id%3D%22paint0_radial%22%20cx%3D%220%22%20cy%3D%220%22%20r%3D%221%22%20gradientUnits%3D%22userSpaceOnUse%22%20gradientTransform%3D%22translate%2810%2010%29%20rotate%2890%29%20scale%2810%29%22%3E%3Cstop%20offset%3D%220.380208%22%20stop-color%3D%22%23EC0606%22%20stop-opacity%3D%220%22%2F%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23EC0606%22%20stop-opacity%3D%220.9%22%2F%3E%0D%0A%3C%2FradialGradient%3E%3C%2Fdefs%3E%3C%2Fsvg%3E') no-repeat top left / 100%",
        },
        '&:hover': {
          '& .title04_more': {
            width: '6.5em',
            color: 'rgba(256,256,256,, 1)',
          },
          opacity: 0.8,
        },
      },
    },
  }),
);

const { NEXT_PUBLIC_RTV_WEB_URL } = process.env;

type Props = {
  unit: WebApiTypes.Unit;
};
const UnitTitle: React.FC<Props> = memo(({ unit }) => {
  const classes = useStyles();
  const { isGameToday } = useTopStoreStateContext();

  // ライブユニットかどうか
  const isLive = useMemo(
    () => Number(unit?.type) === 16 && Number(unit?.multi_id) === MULTI_ID_LIVE && unit?.element_list?.length > 0,
    [unit],
  );

  // チームページかどうか
  const isTeam = useMemo(() => MULTI_IDS_TEAM.includes(Number(unit?.multi_id)), [unit]);

  // 特集記事かどうか
  const isIssue = useMemo(() => isTeam && unit?.title === ISSUE_UNIT_TITLE, [isTeam, unit?.title]);

  const isLink = useMemo(() => unit?.link_url && unit?.element_list?.length > 0, [unit]);

  return (
    <>
      {isLive ? (
        <a href={unit.link_url} className={`${classes.root} title01 ${isLink ? 'link' : ''} title01-live`}>
          <h2 className="title01_txt">{unit.shoulder_title}</h2>
          <span className="title01_more">
            配信日程へ
            <span className="title01_moreIco" />
          </span>
          <span className="title01_shoulder">{isGameToday ? '本日の配信' : '次回の配信'}</span>
        </a>
      ) : (
        <a
          className={`${classes.root} title01 ${isLink ? 'link' : ''}`}
          href={isIssue ? null : `${NEXT_PUBLIC_RTV_WEB_URL}${unit.link_url}`}
        >
          <h2 className="title01_txt">{isTeam ? unit.title : unit.shoulder_title}</h2>
          {!isIssue && (
            <>
              <span className="title01_more">
                もっと見る
                <span className="title01_moreIco" />
              </span>
              <span className="title01_shoulder">{isTeam ? unit.shoulder_title : unit.title}</span>
            </>
          )}
        </a>
      )}
    </>
  );
});

export default UnitTitle;
